import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import CheckIcon from "/public/site-assets/svg/check-mark.svg";

export default function ValidatedValueCard({
    className,
    post,
}) {
    return (
        <div className={clsx(className, styles.root)}>
            <div className='d-flex gap-3'>
                <CheckIcon />
                <div>
                    <div className='post-title'>{post.title}</div>
                    {post.content && (
                        <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }} />
                    )}
                </div>
            </div>
        </div>
    )
}



ValidatedValueCard.defaultProps = {
    pathPrefix: "",
    className: "",
};

ValidatedValueCard.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
};