import React, { Suspense, useState, useEffect, useRef } from "react";
import { Global, Collection, Jobs } from "@sourceflow-uk/sourceflow-sdk";
// import { jobSearch } from "@/functions/jobSearch";
import { SourceFlowHead } from "@sourceflow-uk/sourceflow-head";
import metaObject from "@/.sourceflow/metadata.json";
import sourceflowEnums from "@/sourceflow.enums";
import Wave1 from "@/public/site-assets/svg/footer-wave.svg";
import Wave2 from "@/public/site-assets/svg/faq-wave-1.svg";


// import DualColumnTimelineCTA from "components/DualColumnTimelineCTA";
// import LatestBlogs from "@/components/LatestBlogs";
// import LatestTeam from "@/components/LatestTeam";
// import ImageWithTextSlanted from "components/FocusConsultant";
// import MultiColumnCTA from "components/MultiColumnCTA";
import MainBanner from "components/MainBanner";
import MultiValues from "components/MultiValues";
// import LatestJobs from "components/LatestJobs";
// import SF_LatestTestimonials_001 from "components/SF_LatestTestimonials_001";




// const MainBanner = React.lazy(() => import("components/MainBanner"));
// const MultiValues = React.lazy(() => import("components/MultiValues"));
const LatestJobs = React.lazy(() => import("components/LatestJobs"));
const ImageWithTextSlanted = React.lazy(() => import("components/FocusConsultant"));
const SF_LatestTestimonials_001 = React.lazy(() => import("components/SF_LatestTestimonials_001"));
const MultiColumnCTA = React.lazy(() => import("components/MultiColumnCTA"));
const LatestTeam = React.lazy(() => import("components/LatestTeam"));
const LatestBlogs = React.lazy(() => import("@/components/LatestBlogs"));
const DualColumnTimelineCTA = React.lazy(() => import("components/DualColumnTimelineCTA"));



function LazyLoadComponent({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    // Ensure the page starts at the top
    window.scrollTo(0, 0);

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once the component is in view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref}>
      {isVisible ? children : <div className="loader"><div>Loading...</div></div>} {/* Placeholder until in view */}
    </div>
  );
}

/**
 *
 * @param global
 * @param meta
 * @returns {JSX.Element}
 * @constructor
 */
export default function Home({
  global,
  meta,
  blogs,
  consultants,
  teaching_jobs,
  homepage_values,
  jobs,
  testimonials
}) {
  return (
    <>
      <SourceFlowHead
        metaObject={metaObject}
        title={meta.title}
        description={meta.description}
        canonical={meta.canonical}
      />

      <MainBanner
        global={global}
        pathPrefix={`homepage.page.main_banner`}
        heading={`Because`}
        heading_2={`Matters.`}
        subtitle={`Find your ideal classroom job with the UK's leading education recruitment agency`}
        heading_tag={`h3`}
      />
      <MultiValues
        global={global}
        pathPrefix={`homepage.page.multi_values`}
        post={homepage_values}
        className={`homepage`}
        wave_style_1={false}
      />
      <Suspense fallback={<div className="loader"><div>Loading...</div></div>}>
        <LazyLoadComponent>
          <LatestJobs
            global={global}
            pathPrefix={`homepage.page.latest_jobs`}
            heading={`Latest Jobs`}
            post={jobs}
            with_swiper={true}
            heading_tag={`h4`}
            button_cta_title={`Search Jobs`}
            button_cta_link={`/jobs`}
            className={`homepage py-5 position-relative`}
          />
          <ImageWithTextSlanted
            global={global}
            pathPrefix={`homepage.page.image_with_text_slanted.1`}
            heading={`Experience FREE award-winning CPD`}
            content={
              <>
                <p>Thanks to our exclusive partnership with The National College, our candidates are provided access to more than 2,500 courses. With the training at the tip of your fingers, you’re positioned to land that perfect classroom job.</p>
              </>
            }
            button_cta_title={`Discover CPD opportunities`}
            button_cta_link={`/cpd/the-national-college`}
            imageURL={`/images/placeholders/about-tradewind-difference-placeholder.png`}
            className="style-1 homepage bg-sky-blue flip-column"
            heading_tag={`h3`}
            label_text={`Name Surname, Position`}
            label_image={`/images/placeholders/tnc-logo.png`}
          />
          <SF_LatestTestimonials_001
            global={global}
            pathPrefix={`homepage.page.SF_LatestTestimonials_001`}
            heading={`5 star reviews`}
            heading_tag={`h3`}
            swiperID={`latest_testimonials`}
            className="homepage"
            post={testimonials}
          />
          <MultiColumnCTA
            global={global}
            pathPrefix={`homepage.page.multicolumncta`}
            post={teaching_jobs}
            heading={`Find your next job in Education`}
            cta_title={`Search all jobs`}
            cta_link={`/jobs`}
            className={`homepage py-5`}
          />
          <ImageWithTextSlanted
            global={global}
            pathPrefix={`homepage.page.image_with_text_slanted.2`}
            heading={`Refer a friend!`}
            content={
              <>
                <p>Purus amet ullamcorper fringilla sed phasellus. Et elementum fames massa sit. Proin auctor dictum ornare feugiat malesuada tempus sit. Auctor eget pulvinar eget ac. Sodales risus sed gravida nisl nibh neque nunc id.</p>
              </>
            }
            button_cta_title={`Learn about refer a friend`}
            button_cta_link={`/refer-a-friend`}
            imageURL={`/images/placeholders/about-tradewind-difference-placeholder.png`}
            className="style-1 bg-green bg-wave-1"
            heading_tag={`h3`}
            earn_style={true}
            corner_subtitle={
              <>
                Earn <br />£200!
              </>
            }
          />
          <LatestTeam
            global={global}
            pathPrefix={`homepage.page.latest_team`}
            heading={`Meet our Team Leaders`}
            content={`Who we are`}
            cta_title={`Get in touch`}
            cta_link={`/contact-us`}
            post={consultants}
            className={`homepage pb-5`}
          />
          <div className="footer wave-blue" style={{ marginTop: '-1px' }}>
            <Wave2 />
          </div>
          <LatestBlogs
            global={global}
            pathPrefix={`homepage.page.latest_blogs`}
            post={blogs}
            heading={`Useful Resources for Educators`}
            content={``}
            heading_tag={`h3`}
            cta_title={`View more Insights`}
            cta_link={`/resources`}
            className={`py-5 homepage`}
          />
          <div className="yellow footer position-relative" style={{ marginBottom: '-2px', top: '-1px' }}>
            <Wave1 />
          </div>
          <DualColumnTimelineCTA
            global={global}
            pathPrefix={`homepage.page.dual_column_timeline_cta`}
            left_heading={`Teachers`}
            left_subtitle={`Looking for your next move?`}
            left_content={
              <>
                <p>Register with us today and see for yourself why 94% of our candidates would recommend us to a friend!</p>
              </>
            }
            left_cta_link={`/get-started`}
            left_cta_title={`Register today`}
            right_heading={`Schools`}
            right_subtitle={`The Tradewind Difference`}
            right_content={
              <>
                <p>Proudly supporting nurseries and schools throughout the UK, find out why 98% of our schools would recommend Tradewind.</p>
              </>
            }
            right_cta_link={`/schools/our-services`}
            right_cta_title={`Partner with us`}
            className="homepage"
            content_html={true}
          />
        </LazyLoadComponent>
      </Suspense>
    </>
  );
}

/**
 *
 * @returns {Promise < { props: { meta: { description: string, canonical: string, title: string }, jobs: *, global } } >}
      */
export async function getStaticProps() {
  const global = (await Global()).toJson();
  // const jobs = await jobSearch({limit: 20 });

  const blogs = (await Collection(`blog`)).getItems().slice(0, 6);
  const consultants = (await Collection(`consultants`)).getItems();
  const teaching_jobs = (await Collection(`teaching_jobs`)).getItems();
  const homepage_values = (await Collection(`homepage_values`)).getItems()
  const testimonials = (await Collection(`testimonials`)).getItems().slice(0, 5);

  const jobs = (await Jobs())
    // .filter(function (job) {
    //     return job.categories
    //         // Pull all jobs except internal category
    //         .find((cat) => cat.id != "584b0a68-30f2-4a0e-9380-f41bd9ed9be5");
    // })
    .filter(function (job) {
      return (
        (new Date(job?.expires_at)) >= (new Date())
      )
    })
    .getItems()
    .slice(0, 6);;

  return {
    props: {
      global,
      meta: {
        title: sourceflowEnums.seo.titleTemplate,
        description: sourceflowEnums.seo.genericDescription,
        canonical: sourceflowEnums.seo.baseURL,
      },
      blogs,
      consultants,
      teaching_jobs,
      homepage_values,
      jobs,
      testimonials
    },
  };
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflowtext
// https://github.com/sourceflow-uk/components/pkgs/npm/job-search
