import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import ValidatedValueCard from "@/components/Shared/ValidatedValueCard";

import Wave1 from "@/public/site-assets/svg/wave-6.svg";
import Wave2 from "@/public/site-assets/svg/wave-7.svg";

export default function MultiValues({
    className,
    global,
    pathPrefix,
    post,

    wave_style_1
}) {
    return (
        post?.length >= 1 && (
            <>
                <section className={clsx(className, styles.root)}>
                    {wave_style_1 && (
                        <div className='top-wave wave'>
                            <Wave1 />
                        </div>
                    )}
                    <div className='outer-wrapper'>
                        <Container>
                            <div className='wrapper'>
                                {post.map((post, index) =>
                                    <div className={`column`} key={index}>
                                        <ValidatedValueCard
                                            post={post}
                                        />
                                    </div>
                                )}
                            </div>
                        </Container>
                    </div>
                    {wave_style_1 && (
                        <div className='bottom-wave wave position-relative z-index-1'>
                            <Wave2 />
                        </div>
                    )}
                </section>
            </>
        )
    )
}

MultiValues.defaultProps = {
    pathPrefix: "",
    className: "py-5",

};

MultiValues.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
};